<template lang="pug">
#app
  v-container(grid-list-md, fluid)
    v-layout(v-if="$root.user.permissao != 0", row, wrap)
      AccessLock
    v-layout(v-if="$root.user.permissao == 0", row, wrap)
      v-flex(lg12)
        form(@submit.prevent="salvar")
          v-card.elevation-1
            v-card-text
              v-layout(row, wrap)
                v-flex.md12.xs12
                  v-text-field(label="Nome", color="gray", required, v-validate="'required'", :error-messages="errors.collect('nome')", data-vv-name="nome", data-vv-as="Nome", type="text", v-model='tipo_questionario.nome')
              v-layout(row, wrap)
                v-flex.xs12
                  v-btn(type="submit", color="bt-primary", :loading='loading').white--text Salvar
</template>

<script>
import _ from 'lodash';
import AccessLock from '@/components/AccessLock';

export default {
  components: {
    AccessLock
  },
  $_veeValidate: {
    validator: 'new'
  },
  data: () => ({
    loading: false,
    tipo_questionario: {},
  }),
  mounted () {
    const vm = this;
    if (vm.$route.params.id) {
      vm.getTipoQuestionario(vm.$route.params);
    }
  },
  methods: {
    async save (tipo_questionario) {
      const vm = this;
      try {
        // tipo_questionario.licenca_id = user.usuario_licencas[0].id;
        const response = await vm.$axios.post('/tipo-questionario', tipo_questionario);
        tipo_questionario.id = response.data.id;
        vm.$router.push({ name: 'TipoQuestionarioLista', params: { id: response.data.id } });
        vm.loading = false;
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Tipo de questionário salva com sucesso!' });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao salvar o tipo de questionário. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async update (tipo_questionario) {
      const vm = this;
      try {
        const response = await vm.$axios.put(`/tipo-questionario/${tipo_questionario.id}`, tipo_questionario);
        vm.loading = false;
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Tipo de questionário salvo com sucesso!' });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao atualizar o tipo de questionário. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async salvar (scope) {
      const vm = this;
      try {
        vm.loading = true;  
        const result = await vm.$validator.validateAll();
        if (!result) throw 'Preencha todos os campos corretamente!';
        vm[vm.tipo_questionario.id ? 'update' : 'save'](vm.tipo_questionario);
        vm.$validator.reset();
      } catch (error) {
        window.getApp.$emit('APP_ALERT', { color: 'red', text: error });
        vm.loading = false;
      }
    },
    async getTipoQuestionario (params) {
      let vm = this;
      try {
        const response = await vm.$axios.get(`/tipo-questionario/${params.id}`);
        vm.tipo_questionario = response.data || {};
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
  },
};
</script>

<style scoped>

</style>

